<template>
  <div class="box">
    <!-- <div class="topImg">
      <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/banner.png" alt="" />
      <img src="../assets/home/banner.png" alt="" />
    </div> -->
    <!-- 轮播部分 -->
    <div class="banner">
      <el-carousel :interval="3000" arrow="never">
        <el-carousel-item v-for="bannersItem in banners" :key="bannersItem.id" height="100%">
          <img class="bannerImg" :src="bannersItem.imgUrl" alt="" style="width: 100%"
            @click="routerPush('/productHardSelfHelp')" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="centre-content">
      <!-- 应用方案 -->
      <div class="plan">
        <div class="plan-titleBar">解决方案</div>
        <div class="border-bottom"></div>

        <!-- <div class="plan-content" >
          <div class="plan-content-img" v-for="item in planList" :key="item.id"  @click="routerPush('{{item.name}}')">
            <img :src="item.img" alt="" />
            <div class="plan-content-mark"> 
              <div><img :src="item.icon" alt="" /></div>
              <p class="plan-content-img-text">{{item.title}}</p>
            </div>
          </div>
        </div> -->

        <div class="plan-content">
          <div class="plan-content-img" @click="routerPush('/schemeHigher')">
            <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/three.png" alt="" />
            <div class="plan-content-mark">
              <div><img src="../assets/home/three-top.png" alt="" /></div>
              <p class="plan-content-img-text">高等教育</p>
            </div>
          </div>
          <div class="plan-content-img" @click="routerPush('/schemeBasics')">
            <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/two.png" alt="" />
            <div class="plan-content-mark">
              <div><img src="../assets/home/two-top.png" alt="" /></div>
              <p class="plan-content-img-text">基础教育</p>
            </div>
          </div>
          <div class="plan-content-img" @click="routerPush('/schemeTrade')">
            <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/one.png" alt="" />
            <div class="plan-content-mark">
              <div><img src="../assets/home/one-top.png" alt="" /></div>
              <p class="plan-content-img-text">中等职业教育</p>
            </div>
          </div>
          <div class="plan-content-img" @click="routerPush('/schemeTrain')">
            <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/four.png" alt="" />
            <div class="plan-content-mark">
              <div><img src="../assets/home/four-top.png" alt="" /></div>
              <p class="plan-content-img-text">教师培训</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品体系 -->
      <div class="plan">
        <div class="plan-titleBar">产品体系</div>
        <div class="border-bottom"></div>
        <div class="plan-content">
          <div class="product-box">
            <div class="chanPin-left-icon" @click="changeProduct(-1)"><img src="../assets/home/product-left-icon.png"
                alt="" /></div>
            <div class="chanPin-left-icon" @click="changeProduct(-1)" v-if="this.bannersIndex > 0"><img
                src="../assets/home/product-left-icon2.png" alt="" /></div>
            <div class="product-content-list">
              <div class="product-content-list-content" :style="{
                width: 384 * 9 + 24 * 8 + 'px',
                left: bannersIndex * (384 + 24) * -1 + 'px',
              }">
                <div class="product-content" v-for="item in arrList" :key="item.id">
                  <div class="product-top">
                    <div class="product-title">{{ item.title }}</div>
                    <div class="productImg">
                      <img v-bind:src="item.topImg" alt="" />
                    </div>
                  </div>
                  <div class="product-text">
                    {{ item.info }}
                  </div>
                  <router-link :to="{ name: 'productSoft', params: { index: item.index } }" class="seeMore">
                    了解更多
                    <div style="margin-left: 10px" class="seeMoreImg"><img src="../assets/home/seemore-btn.png" alt="" />
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="chanPin-right-icon" @click="changeProduct(1)"><img src="../assets/home/product-right-icon.png"
                alt="" /></div>
            <div class="chanPin-right-icon" @click="changeProduct(1)" v-if="this.bannersIndex > 5"><img
                src="../assets/home/product-right-icon1.png" alt="" /></div>
          </div>
          <div class="product-bottom">
            <div class="product-bottom-img" v-for="item in productList" :key="item.id">

              <div class="product-bottom-img-hover">
                <h4>{{ item.title }}</h4>
                <p class="product-bottom-img-hover-text">{{ item.text }}</p>
                <p class="product-bottom-img-hover-text">{{ item.info }}</p>
                <router-link to="/productHard" class="hoverSeeMore seeMore">
                  了解更多
                  <div style="margin-left: 10px" class="seeMoreImg"><img src="../assets/home/seemore-btn2.png" alt="" />
                  </div>
                </router-link>
              </div>

              <img v-bind:src="item.src" alt="" />
              <div class="product-bottom-img-text">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 课程资源 -->
    <div class="bj">
      <div class="plan-titleBar plan-titleBar2">课程资源</div>
      <div class="border-bottom"></div>
      <div class="centre-content">
        <div class="block">
          <!-- :autoplay="false" 禁止自动播放 -->
          <el-carousel arrow="always" :interval="3000" class="classBanner">
            <el-carousel-item class="classZiYuan" v-for="item in classBanners" style="margin-top: 50px; height: 560px"
              :key="item.id">
              <img :src="item.url" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="class-list" @click="routerPush('/contentClass')">查看更多课程</div>
    </div>
    <!-- 动态 -->
    <div class="centre-content">
      <div>
        <div class="plan-titleBar">动态</div>
        <div class="border-bottom"></div>
        <div class="move">
          <div style="height: 434px; overflow: hidden">
            <div class="moveText-box" v-for="newsListItem in newsList" :key="newsListItem.id"
              @click="toNoticeList(newsListItem.id)">
              <div style="display: flex">
                <el-tag>{{ newsListItem.cateName }}</el-tag>
                <p class="move-title">
                  {{ newsListItem.title }}
                </p>
              </div>
              <p class="move-time">
                发布时间：<span>{{ newsListItem.createdAt }}</span>
              </p>
              <p class="move-text">
                {{ newsListItem.summary }}
              </p>
            </div>
          </div>
          <router-link to="/move">
            <a class="move-seemore"> 查看更多 <img src="../assets/home/seemore-btn.png" alt="" /> </a>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get } from '../utils/xajax'
import { getItem } from '../utils/storage'
export default {
  name: '',
  data() {
    return {
      newsList: [],
      banners: [],
      bannersIndex: 0,
      classBanners: [
        { url: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/lunbo1.png' },
        { url: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/lunbo2.png' },
        { url: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/lunbo3.png' },
      ],
      arrList: [
        {
          title: '心理测评系统',
          index: '0',
          topImg: require('../assets/home/product-one-icon.png'),
          info: '心理测评系统支持定向测试和集体测试两种模式，经过定制量表、发布测评、开展测评、过程监控和结果管理几个步骤完成测评，最终自动生成测评报告。系统根据学校需求不同，提供了标准化量表库和个性化量表管理，学校可以选择标准量表，也可以导入个性化量表或者自定义量表，大大提高了…',
        },
        {
          title: '心理咨询系统',
          index: '1',
          topImg: require('../assets/home/product-seven-icon.png'),
          info: '系统根据不同的咨询场景提供多种咨询服务形式，保障同学们的咨询体验，具体形式包括：针对简单的咨询问题，通过智能咨询和机器人的方式解答；针对稍微复杂的常见问题，可以通过在线专家咨询的方式进行，支持实时和非实时两种模式；针对比较复杂的问题采用线下咨询的模式，支持咨询…',
        },
        {
          title: '课程教学系统',
          index: '2',
          topImg: require('../assets/home/product-five-icon.png'),
          info: '课程教学系统主要解决学校的心理课程教学问题，涵盖多种教学模式，一种是线上教学模式，通过提供精品在线课程或者个性化定制校本课程，实现学生自助线上学习，支持在线视频、作业、讨论和考试；一种是混合式教学模式，通过平台有效支撑教师进行教学改革，完成课前自主学习、课中讨…',
        },
        { title: '干预管理系统', index: '6', topImg: require('../assets/home/product-two-icon.png'), info: '干预管理系统是对需要干预的学生进行全程管理的信息化支撑工具，系统提供了全流程管理功能，方便地实现干预清单的及时分发、任务管理和结果管理。' },
        {
          title: '重点关注系统',
          index: '5',
          topImg: require('../assets/home/product-three-icon.png'),
          info: '测评产生的名单将进入访谈环节，由各院系通过访谈进一步确认学生的情况。访谈管理系统提供了任务分配、访谈管理、过程留痕等功能，有效地将院系负责人、辅导员组织到访谈工作中来，做到分工明确、过程可查、结果可控。访谈结果通过标签方式进一步分组，产生待咨询清单，进入咨询…',
        },
        {
          title: '访谈管理系统',
          index: '4',
          topImg: require('../assets/home/product-four-icon.png'),
          info: '心理测评系统支持定向测试和集体测试两种模式，经过定制量表、发布测评、开展测评、过程监控和结果管理几个步骤完成测评，最终自动生成测评报告。系统根据学校需求不同，提供了标准化量表库和个性化量表管理，学校可以选择标准量表，也可以导入个性化量表或者自定义量表，大大提高了…',
        },
        {
          title: '档案管理系统',
          index: '8',
          topImg: require('../assets/home/product-six-icon.png'),
          info: '通过全流程数据留痕，平台完整记录了学生在评测、课程、活动、咨询中的表现，完整记录了教师在平台进行教学、咨询和服务的完整表现，这样就可以生成学生和教师画像，以及学生和教师成长档案，实现“一生一档案，一师一档案”。还可以基于运行数据，生成《大学生心理健康咨询与教育…',
        },
        {
          title: '门户管理系统',
          index: '7',
          topImg: require('../assets/home/product-eight-icon.png'),
          info: '门户管理系统定位于展现学校心理健康咨询与教育工作的相关服务和成果，具体包括宣传心理健康教育相关政策，展示中心具体工作，及时发布组织活动，普及心理健康教育知识。满足学校建设融思想性、知识性、趣味性、服务性于一体的心理健康教育网站的需求，促进学生关注心理健康、助人…',
        },
        {
          title: '活动管理系统',
          index: '3',
          topImg: require('../assets/home/product-nine-icon.png'),
          info: '通过全流程数据留痕，平台完整记录了学生在评测、课程、活动、咨询中的表现，完整记录了教师在平台进行教学、咨询和服务的完整表现，这样就可以生成学生和教师画像，以及学生和教师成长档案，实现“一生一档案，一师一档案”。还可以基于运行数据，生成《大学生心理健康咨询与教育…',
        },
      ],
      productList: [
        {
          title: '团体活动室',
          src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/1.png',
          text: '可配置设备：团体活动包、游戏、素质拓展包、心理挂图及制度',
          info: '该室可用于提升人际交往能力、合作意识和个人优势开发。也可用于开展团体辅导活动，或者用于教师开展教学研讨、教师培训等活动。',
        },
        { title: '智能宣泄室', src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/22.png', text: '可配置设备：不倒翁人形沙袋、呐喊宣泄系统、打击宣泄系统等', info: '该室可用于通过各种道具和宣泄系统的应用，舒缓个人紧张情绪，让人回归平静。' },
        { title: '音乐减压室', src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/3.png', text: '可配置设备：音乐治疗自助减压系统软件、心理挂图及制度', info: '该室可用于音乐放松训练，消除悲观、焦虑紧张等负面情绪，改善睡眠质量。该室亦可用于辅助催眠。' },
        { title: '个体咨询室', src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/4.png', text: '可配置设备：沙发、茶几、绿植、心理图书、心理挂图及制度', info: '该室可用于倾吐心声、认识自我、增强来访者的心理适应能力、心理承受能力。' },
        { title: 'VR训练室', src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/5.png', text: '可配置设备：桌椅、电脑、沙发、系统软件、心理挂图及制度', info: '该室可用于情绪控制训练、抗挫折培训、学习压力管理。可提升幸福感、改善考试状态、缓解考试焦虑、改善人际关系。' },
        { title: '沙盘游戏室', src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/6.png', text: '可配置设备：立柜、桌子、沙盘游戏、心理挂图及制度', info: '该室可通过沙盘游戏的非言语支持，激发自我力量，化解内心冲突与压力。' },
      ],
      planList: [
        { title: '高等教育', img: require('../assets/home/three.png'), icon: require('../assets/home/three-top.png'), name: '/schemeHigher' },
        { title: '基础教育', img: require('../assets/home/two.png'), icon: require('../assets/home/two-top.png'), name: '/schemeBasics' },
        { title: '中等职业教育', img: require('../assets/home/one.png'), icon: require('../assets/home/one-top.png'), name: '/schemeTrade' },
        { title: '教师培训', img: require('../assets/home/four.png'), icon: require('../assets/home/four-top.png'), name: '/schemeTrain' },
      ],
    }
  },
  methods: {
    routerPush(path) {
      this.$router.push({ path })
    },
    getList() {
      get('/index/home').then((res) => {
        this.newsList = res.data.newsList
        this.banners = res.data.banners
      })
    },
    toNoticeList(id) {
      this.$router.push({ path: `/newsDetial/${id}` })
    },

    changeProduct(val) {
      let index = this.bannersIndex
      // console.log(index)
      index = index + val
      if (index < 0) {
        index = 0
      } else if (index > 6) {
        index = 6
      }
      this.bannersIndex = index
    },
  },
  mounted() {
    // // 打印显示器分辨率
    console.log(window.screen.width)
    console.log(window.screen.height)

    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0)
    }),
      this.getList()
  },
}
</script>

<style lang="less" scoped>
.bannerImg:hover {
  -webkit-transform: rotate(180deg);
  transform: rotate(360deg);
}

a {
  text-decoration: none;
}

.box {
  width: 100%;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 1rem;
  line-height: 7.5rem;
  text-align: center;
  background-color: #39a9ed;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

// 轮播部分
/deep/ .el-carousel__indicator--horizontal {
  bottom: 0;
  left: 22%;
  top: 88%;
  transform: translate(-20%, 0%);
}

/deep/.el-carousel__button {
  width: 64px;
  height: 4px;
  border-radius: 2px;
}

.el-carousel {
  height: 100%;
}

/deep/.el-carousel__container {
  height: 680px !important;
}

/deep/.el-carousel__item>img {
  height: 680px !important;
}

// 媒体查询 分辨率 900 - 1440
@media screen and (min-width: 900px) and (max-width: 1440px) {
  /deep/.el-carousel__item {
    height: 520px !important;
  }

  /deep/.el-carousel__item>img {
    height: 520px !important;
  }

  /deep/.el-carousel__container {
    height: 520px !important;
  }

  /deep/.class-list {
    margin-top: 50px !important;
  }

  .block {
    height: 620px;
  }

  .classZiYuan {
    height: 570px !important;
  }
}

/deep/.el-carousel__indicators--horizontal {
  left: 25% !important;
  bottom: 10% !important;
  transform: translateX(-50%) !important;
}

// 中心内容部分
.centre-content {
  width: 1200px;
  margin: 0 auto;
}

.plan-titleBar {
  height: 56px;
  font-size: 32px;
  font-weight: 500;
  color: #3b3d41;
  line-height: 56px;
  margin: 0 auto;
  padding-bottom: 10px;
  padding-top: 74px;
  display: flex;
  justify-content: space-around;
}

.plan-titleBar2 {
  padding-top: 60px;
}

.border-bottom {
  width: 48px;
  height: 4px;
  background: #4676f8;
  border-radius: 2px;
  margin: auto;
}

//应用方案
.plan-content {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}

.plan-content-img {
  display: flex;
  position: relative;
  margin-top: 45px;
  cursor: pointer;
}

.plan-content-img>img {
  width: 580px;
  height: 230px;
}

.plan-content-img:hover {
  box-shadow: 1px 3px 10px 4px rgba(43, 42, 42, 0.3);
}

.plan-content-mark {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 10%;
  text-align: center;
}

.plan-content-img-text {
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  padding-top: 15px;
  text-align: center;
}

.plan-content>img {
  width: 580px;
  height: 270px;
}

.product-box {
  width: 1200px;
  box-sizing: border-box;
  //   height: 394px;
  height: 317px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  margin-top: 50px;
  position: relative;
}

.product-content-list {
  width: 1200px;
  height: 394px;
  position: relative;
  overflow: hidden;
}

.product-content-list-content {
  height: 394px;
  transition: all 0.5s;
  position: absolute;
}

.chanPin-left-icon,
.chanPin-right-icon {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.chanPin-left-icon {
  margin-right: 45px;
  left: -100px;
}

.chanPin-right-icon {
  margin-left: 45px;
  right: -100px;
}

.product-content {
  width: 384px;
  height: 317px;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-right: 24px;
}

.product-content:last-child {
  margin-right: 0;
}

.product-top {
  display: flex;
  justify-content: space-between;
  width: 384px;
  height: 93px;
  line-height: 93px;
  background: linear-gradient(to right, #0156ff, #347afd);
}

.product-title {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  padding-left: 32px;
}

.productImg>img {
  padding-right: 30px;
  width: 89px;
  height: 89px;
}

.product-text {
  height: 134px;
  margin: 24px;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #3b3d41;
  width: 336px;
  // 多行省略号
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}

.seeMore {
  float: right;
  height: 22px;
  margin: 0 24px;
  font-size: 16px;
  font-weight: 400;
  color: #0156ff;
  position: absolute;
  bottom: 32px;
  right: 0px;
  display: flex;
}

.seeMoreImg>img {
  width: 7px;
}

.product-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}

.product-bottom-img {
  margin-top: 32px;
  width: 384px;
  height: 274px;
  display: flex;
  position: relative;
  cursor: pointer;
}

.product-bottom-img:hover .product-bottom-img-hover {
  display: block;
  background: linear-gradient(to right, #0156ff, #7caefc);
  animation: 5000s;
}

h4 {
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 28px;
  padding: 40px 32px 16px;
}

.product-bottom-img-hover-text {
  height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  padding: 0px 32px 20px;
}

.hoverSeeMore {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: white;
  line-height: 22px;
}

.product-bottom-img-hover {
  width: 384px;
  height: 274px;
  background: #4676f8;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  animation: myMove steps(11) 0.6s forwards;
  animation: 5000s;
}

.product-bottom-img-text {
  position: absolute;
  top: 50%;
  left: 40%;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
}

// 课程资源
.bj {
  background-image: url(../assets/home/bjClass.png);
  margin-top: 74px;
  height: 950px;
}

.class-list {
  border-radius: 31px;
  border: 1px solid #0156ff;
  margin: 0 auto;
  width: 278px;
  height: 62px;
  line-height: 62px;
  font-size: 20px;
  font-weight: 600;
  color: #0156ff;
  letter-spacing: 1px;
  text-align: center;
  //   margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;
}

.classZiYuan>img {
  //   height: 560px;
  height: 560px !important;
}

// 动态
.move {
  width: 100%;
  margin-top: 50px;
}

.moveText-box:first-child {
  margin-top: 0px;
}

.moveText-box {
  margin-top: 42px;
  cursor: pointer;
}

.moveText-box:hover .move-title {
  color: #0156ff;
}

.move-title {
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  line-height: 28px;
  margin-left: 14px;
}

.move-time {
  height: 22px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #6f7174;
  line-height: 22px;
  letter-spacing: 1px;
  margin-top: 10px;
}

.move-text {
  width: 1200px;
  height: 44px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #3b3d41;
  line-height: 22px;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.move-seemore {
  display: block;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0156ff;
  line-height: 22px;
  margin-top: 40px;
  margin-bottom: 150px;
  cursor: pointer;
  text-align: center;
}

.move-seemore>img {
  width: 7px;
}

/deep/.el-tag {
  color: #4676f8;
  border-color: #0156ff;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0156ff;
}

</style>