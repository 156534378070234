<template>
  <div class="box">
    <div class="topImg">
      <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/top-img.png" alt="" />
      <div class="topImg-headline">
        <div class="topImg-title">产品-软件</div>
      </div>
    </div>
    <!-- 中心内容部分 -->
    <div class="center-content">
      <div class="center-one">
        <div class="bj-img">
          <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/xling-tong.png" alt="" />
        </div>
        <div class="bj">
          <p>
            公司秉持数字化赋能心理健康教育的发展理念，基于互联网技术整合行业专家、优质课程和特色服务。为高校提供软件平台、智能硬件、课程资源、功能室建设、师资培训等一站式服务解决方案，助力高校提高工作效能、降低危机事件概率、整体提升学生心理健康素养。
          </p>
        </div>
      </div>
      <div class="center-two">
        <div>
          <div class="plan-titleBar" ref="plan-titleBar">九大系统</div>
          <div>
            <el-tabs :tab-position="tabPosition" v-model="tabsValue" style="margin-top: 70px">
              <el-tab-pane label="精细化心理测评平台" name="0">
                <div style="margin-left: 60px">
                  <div class="center-two-title">精细化心理测评平台</div>
                  <p class="center-two-text">
                    平台支持定向测评和集体测评两种模式，通过确定量表、发布测评、开展测评、过程监控和结果管理等环节完成测评工作，最终自动生成测评报告。平台具有量表全面可定制、流程规范网络化、操作简单便捷化、报告全面自动化四个特点，帮助学校快速开展心理测评工作。
                  </p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/ruanjian1.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="多元化咨询指导平台">
                <div style="margin-left: 60px">
                  <div class="center-two-title">多元化咨询指导平台</div>
                  <p class="center-two-text">
                    为保障学生咨询体验，平台根据不同咨询场景提供多种形式的咨询服务，包括：智能AI咨询解答简单问题，线下咨询解答复杂问题，支持咨询预约、过程记录、咨询处理和自动归档等功能，自主开启咨询排队、精准匹配，排队概况一目了然。

                  </p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/ruanjian2.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="全程化危机干预平台">
                <div style="margin-left: 60px">
                  <div class="center-two-title">全程化危机干预平台</div>
                  <p class="center-two-text">
                    通过横向工作流程和纵向自动识别的网格化管理体系，从多个维度识别潜在危机。利用信息化技术，实现从危机识别、上报、评估、确认到干预的全流程闭环管理和精准干预。
                  </p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/ruanjian3.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="个性化心理教育平台">
                <div style="margin-left: 60px">
                  <div class="center-two-title">个性化心理教育平台</div>
                  <p class="center-two-text">支持课程学习、微视频观看和分享、图书阅读和活动组织实施等功能，实时记录学习进度并记入学生档案。
                  </p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/ruanjian4.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="教师发展支撑平台">
                <div style="margin-left: 60px">
                  <div class="center-two-title">教师发展支撑平台</div>
                  <p class="center-two-text">
                    为学校老师提供心理测评、心理健康教学资源库和心理健康培训为一体的综合性服务，助力提升学校教师心理健康工作的质量与效率。

                  </p>
                  <div class="center-two-img">
                    <img style="width: 100%;" src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/ruanjian5.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <!-- <el-tab-pane label="重点关注系统">
                <div style="margin-left: 60px">
                  <div class="center-two-title">重点关注系统</div>
                  <p class="center-two-text">
                    重点关注系统是一个日常工作系统，用于院系辅导员、心理委员在日常生活学习过程中上报一些异常情况， 比如行为异常、突发事件涉及到的学生，进入重点关注系统，重点关注分为校级关注和院级关注。
                    辅导员和心理委员要将重点关注学生的一些谈话记录、行为记录、重要事项、处理办法及时上传系统，做到一生一策，过程可控。
                  </p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/center-two-second.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="干预管理系统">
                <div style="margin-left: 60px">
                  <div class="center-two-title">干预管理系统</div>
                  <p class="center-two-text">干预管理系统是对需要干预的学生进行全程管理的信息化支撑工具，系统提供了全流程管理功能，方便地实现干预清单的及时分发、任务管理和结果管理。</p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/center-two-eignth.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="门户管理系统">
                <div style="margin-left: 60px">
                  <div class="center-two-title">门户管理系统</div>
                  <p class="center-two-text">
                    门户管理系统定位于展现学校心理健康咨询与教育工作的相关服务和成果，具体包括宣传心理健康教育相关政策，展示中心具体工作， 及时发布组织活动，普及心理健康教育知识。满足学校建设融思想性、知识性、趣味性、服务性于一体的心理健康教育网站的需求，
                    促进学生关注心理健康、助人自助、快乐成长，营造健康和谐、积极向上的校园氛围。门户管理系统包括栏目管理、内容管理等相关功能，支持多种框架，支持个性化定制。
                  </p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/center-two-fifth.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="档案管理系统">
                <div style="margin-left: 60px">
                  <div class="center-two-title">档案管理系统</div>
                  <p class="center-two-text">
                    通过全流程数据留痕，平台完整记录了学生在评测、课程、活动、咨询中的表现，完整记录了教师在平台进行教学、咨询和服务的完整表现，
                    这样就可以生成学生和教师画像，以及学生和教师成长档案，实现“一生一档案，一师一档案”。还可以基于运行数据，生成《大学生心理健康咨询与教育发展研究》等分析报告， 邀约专家给予诊断建议，为建设示范性大学生心理健康咨询中心提供指导建议。
                  </p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/center-two-sixth.png" alt="" />
                  </div>
                </div>
              </el-tab-pane> -->
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabPosition: 'left',
      //   tabsValue: ''
    }
  },
  mounted() {
    // console.log(this.$route)
    let tabIndex = this.$route.params.index
    if (tabIndex) {
      // 改变当前选中
      this.tabsValue = tabIndex
      // console.log(this.$refs['plan-titleBar'])
      this.$nextTick(() => {
        this.$refs['plan-titleBar'].scrollIntoView({ behavior: 'smooth', block: 'start' })
      })
    }
  },
  computed: {
    tabsValue: {
      get() {
        return this.$store.state.productSoftActive + ''
      },
      set(newval) {
        this.$store.state.productSoftActive = newval
      },
    },
  },
}
</script>

<style lang="less" scoped>
@import '../css/global.css';
.topImg-headline {
  width: 1200px;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
.topImg-title {
  line-height: 108px;
}
// 中心内容
.center-content {
  width: 1200px;
  margin: 0 auto;
  //   height: 2000px;
  height: 1600px;
  background: #ffffff;
}
.center-one {
  padding-top: 60px;
  position: relative;
}
.bj {
  background-color: #075bff;
  width: 1067px;
  height: 348px;
  position: absolute;
  top: 60px;
  left: 130px;
  z-index: 0;
}
.bj > p {
  width: 482px;
  height: 224px;
  float: right;
  padding: 50px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
}
.bj-img {
  margin-top: 36px;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.bj-img > img {
  width: 618px;
}

.plan-titleBar {
  height: 56px;
  font-size: 32px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 56px;
  margin: 0 auto;
  padding-bottom: 14px;
  padding-top: 74px;
  display: flex;
  letter-spacing: 6px;
  justify-content: space-around;
}
.center-two-title {
  height: 40px;
  line-height: 34px;
  border-bottom: 1px solid #e1e1e1;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3b3d41;
  letter-spacing: 2px;
}
.center-two-text {
  font-size: 16px;
  line-height: 26px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  margin: 50px 0px;
}
.center-two-img > img {
  width: 976px;
}

/deep/.el-tabs__item {
  height: 90px;
  line-height: 90px;
}
/deep/.el-tabs__active-bar {
  background-color: #0156ff;
  height: 50px !important;
  margin-top: 0px;
}
/deep/.el-tabs__item.is-active {
  color: #0156ff;
}
/deep/.el-tabs__item:hover {
  color: #0156ff;
  cursor: pointer;
}
/deep/.el-tabs__item {
  height: 75px;
  line-height: 40px;
}
</style>