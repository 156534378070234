import Vue from 'vue';
import VueRouter from 'vue-router';
import homeLayout from '../layout/homeLayout';
import index from '../views/home';
import move from '../views/move';
import schemeBasics from '../views/schemeBasics';
import schemeTrade from '../views/schemeTrade';
import schemeHigher from '../views/schemeHigher';
import aboutUs from '../views/aboutUs';
import productHard from '../views/productHard';
import productHardSelfHelp from '../views/productHardSelfHelp'
import productHardRobot from '../views/productHardRobot'
import contentClass from '../views/contentClass';
import contentSelf from '../views/contentSelf';
import productSoft from '../views/productSoft';
import newDetail from '../views/newDetail';
import schemeTrain from '../views/schemeTrain';
import videoPlay from '../views/videoDetail';
import err404 from '../views/err404.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: homeLayout,
    redirect: '/index', //路由重定向
    children: [
      {
        path: 'index',
        name: 'index',
        meta: {
          title: '心灵通 - 一站式心理健康工作平台',
        },
        component: index,
      },
      {
        path: 'move',
        name: 'news',
        meta: {
          title: '动态 - 北京心灵通科技有限公司',
        },
        component: move,
      },
      {
        path: 'schemeBasics',
        name: 'schemeBasics',
        meta: {
          title: '基础方案 - 北京心灵通科技有限公司',
        },
        component: schemeBasics,
      },
      {
        path: 'aboutUs',
        name: 'aboutUs',
        meta: {
          title: '关于我们 - 北京心灵通科技有限公司',
        },
        component: aboutUs,
      },

      {
        path: 'productHard',
        name: 'productHard',
        meta: {
          title: '硬件 - 北京心灵通科技有限公司',
        },
        component: productHard,
      },
      {
        path: 'productHardSelfHelp',
        name: 'productHardSelfHelp',
        meta: {
          title: '硬件 - 北京心灵通科技有限公司',
        },
        component: productHardSelfHelp,
      },
      {
        path: '/productHardRobot',
        name: 'productHardRobot',
        meta: {
          title: '硬件 - 北京心灵通科技有限公司'
        },
        component: productHardRobot
      },
      {
        path: 'productSoft',
        name: 'productSoft',
        meta: {
          title: '软件 - 北京心灵通科技有限公司',
        },
        component: productSoft,
      },
      {
        path: 'contentClass',
        name: 'contentClass',
        meta: {
          title: '课程资源 - 北京心灵通科技有限公司',
        },
        component: contentClass,
      },
      {
        path: 'contentSelf',
        name: 'contentSelf',
        
        meta: {
          title: '自助资源 - 北京心灵通科技有限公司',
          keepAlive:true
        },
        component: contentSelf,
      },
      {
        path: 'schemeTrade',
        name: 'schemeTrade',
        meta: {
          title: '中等方案 - 北京心灵通科技有限公司',
        },
        component: schemeTrade,
      },
      {
        path: 'schemeHigher',
        name: 'schemeHigher',
        meta: {
          title: '高等方案 - 北京心灵通科技有限公司',
        },
        component: schemeHigher,
      },
      {
        path: 'schemeTrain',
        name: 'schemeTrain',
        meta: {
          title: '培训 - 北京心灵通科技有限公司',
        },
        component: schemeTrain,
      },
      {
        path: 'videoPlay/:id',
        name: 'videoPlay',
        meta: {
          title: '视频播放 - 北京心灵通科技有限公司',
        },
        component: videoPlay,
      },
      {
        path: 'newsDetial/:artId',
        name: 'newsDetial',
        component: newDetail,
      },
    ],
  },
  {
    path: '/404',
    name: err404,
    component: err404,
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
