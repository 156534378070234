<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/scheme/top-img-one.png" alt="" />
      <div class="topImg-headline">
        <div class="topImg-title">基础教育</div>
        <div class="topImg-text">心理危机筛查与干预解决方案</div>
      </div>
    </div>
    <!-- <div class="center-content">111</div> -->
    <div class="center-one">
        <div class="center-one-content">
            <h1>基础教育用户需求</h1>
            <div style="display:flex; justify-content: space-between;">
                <div class="centerImg">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/one.png" alt="">
                    <p class="center-one-content-title">心理测评</p>
                </div>
                <div class="centerImg">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/two.png" alt="">
                    <p class="center-one-content-title">心理干预</p>
                </div>
                <div class="centerImg">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/three.png" alt="">
                    <p class="center-one-content-title">教师培训</p>
                </div>
            </div>
        </div>
    </div>
    <div class="center-two">
        <div class="center-two-content">
            <h1>心理危机筛查与干预总体工作流程</h1>
            <div class="center-two-contentImg">
                <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/fangan-jichu.png" alt="">
            </div>
        </div>
    </div>
    <!-- <div class="center-three">
        <div class="center-three-content">
            <h1>心理危机筛查结果分类处理规范</h1>
            <div class="center-two-contentImg">
                <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/five.png" alt="">
            </div>
        </div>
    </div> -->
    <div class="center-four">
        <div>
            <h1>解决方案交付内容</h1>
            <template>
                <el-tabs v-model="activeName">
                    <el-tab-pane label="心理测评工作平台"  name="first">
                        <div >
                            <p class="center-four-first-title" style="text-align:center;">通过PC/小程序实现场景化的心理普测和单独测试，支持多次重复测试</p>
                            <div style="display:flex;justify-content: space-evenly;align-items: center;">
                                <div  class="center-four-btn"> 知情同意书 </div>
                                <div class="center-four-icon"><img src="../assets/scheme/center-four-one.png" alt=""></div>
                                <div  class="center-four-btn"> 指导语和基本信息 </div>
                                <div class="center-four-icon"><img src="../assets/scheme/center-four-one.png" alt=""></div>
                                <div  class="center-four-btn"> 回答问题 </div> 
                                <div class="center-four-icon"><img src="../assets/scheme/center-four-one.png" alt=""></div>
                                <div  class="center-four-btn"> 完成提交 </div>
                            </div>
                            <div style="display:flex;justify-content: space-between;margin-top:24px;margin-bottom:60px;">
                                <div class="center-fourImg">
                                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/center-four-first-one.png" alt="">
                                </div>
                                <div class="center-fourImg">
                                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/center-four-first-two.png" alt="">
                                </div>
                                <div class="center-fourImg">
                                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/center-four-first-three.png" alt="">
                                </div>
                                <div class="center-fourImg">
                                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/center-four-first-four.png" alt="">
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="丰富的心理测评量表库" name="second">
                        <div class="center-four-img">
                            <p class="center-four-second-title">量表引入国内外权限专家开发并经过行业实践验证的通用量表，包括心理健康筛查类和心理健康发展类，量表总数不少于50个。</p>
                            <div style="padding-bottom:60px;">
                                <img style="width: 100%;" src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/fangan-jichu2.png" alt="">
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="针对性的教师培训体系" name="third">
                        <div class="center-four-img">
                            <p class="center-four-second-title">根据高等教育、中等职业教育和基础教育的特点，针对管理部门、咨询师、课程授课教师、辅导员等角色提供专业的培训课程体系， 激活教师团队，提升本土化的心理危机识别与干预能力；培训分为线上培训和线下培训两种模式，支持为学校定制个性化培训方案。</p>
                            <div class="alignItemsCenter">
                                <div class="center-four-third-left">
                                    <div style="display:flex;margin-top:40px;margin-left:55px;">
                                        <div class="alignItemsCenter left-box">
                                            <img src="../assets/scheme/four-third-one.png" alt="">
                                            <div class="center-four-third-left-title">基本素养培训</div>
                                        </div>
                                        <div>
                                            <ul>
                                                <li>心理危机筛查与干预相关政策 </li>
                                                <li>基础教育常见心理问题及处理办法</li>
                                                <li>心理危机筛查和干预过程中的注意事项</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div style="display:flex;margin-top:40px;margin-left:55px;">
                                        <div class="alignItemsCenter left-box">
                                            <img src="../assets/scheme/four-third-two.png" alt="">
                                            <div class="center-four-third-left-title">风险筛选技能培养</div>
                                        </div>
                                        <div>
                                            <ul>
                                                <li>基础教育心理危机筛查与干预主要流程和工作内容 </li>
                                                <li>学生一对一访谈和风险识别培训</li>
                                                <li>心理咨询常见问题及方法</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div style="display:flex;margin-top:40px;margin-left:55px;">
                                        <div class="alignItemsCenter left-box">
                                            <img src="../assets/scheme/four-third-three.png" alt="">
                                            <div class="center-four-third-left-title">管理和技术培训</div>
                                        </div>
                                        <div>
                                            <ul>
                                                <li>心理危机筛查与干预整体流程培训  </li>
                                                <li>心理危机筛查与干预管理员培训</li>
                                                <li>心理测评培训</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="center-four-third-right">
                                    <img src="../assets/scheme/center-four-third-right.png" alt="">
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="全流程的服务支撑体系" name="fourth">
                        <div class="zhongdengBj"> <img src="../assets/scheme/jichu-Bj.png" alt=""> </div>
                        <div class="center-four-img">
                            <p class="center-four-second-title" style="text-align:center;">通过应用培训、线下支持、电话答疑、普测报告等方式帮助学校解决在普测过程中的一系列技术与应用问题。</p>
                            <div style="display:flex;justify-content: space-between;">
                                <div class="center-four-fourth">
                                    <div style="padding:0 32px;">
                                        <div style="margin:40px 0px 14px;text-align: center;">
                                            <img src="../assets/scheme/four-fourth-one.png" alt="">
                                        </div>
                                        <div class="center-four-fourth-title"> 应用培训 </div>
                                        <p class="center-four-fourth-text">通过线上、线下等方式对管理员、教师和学生进行培训，提供产品操作视频、应用视频等资源。</p>
                                    </div>
                                </div>
                                <div class="center-four-fourth">
                                    <div style="padding:0 32px;">
                                        <div style="margin:40px 0px 14px;text-align: center;">
                                            <img src="../assets/scheme/four-fourth-two.png" alt="">
                                        </div>
                                        <div class="center-four-fourth-title"> 平台运维 </div>
                                        <p class="center-four-fourth-text">通过SaaS方式提供服务，平台、硬件、资源服务化，学校投入少，负担小。平台功能、性能实时优化，保障用户使用体验。</p>
                                    </div>
                                </div>
                                <div class="center-four-fourth">
                                    <div style="padding:0 32px;">
                                        <div style="margin:40px 0px 14px;text-align: center;">
                                            <img src="../assets/scheme/four-fourth-three.png" alt="">
                                        </div>
                                        <div class="center-four-fourth-title"> 技术支持 </div>
                                        <p class="center-four-fourth-text">通过线上线下两种方式提供覆盖全流程的技术支持服务，解决管理员、教师和学生在使用中的问题，保障测评工作的顺利开展。</p>
                                    </div>
                                </div>
                                <div class="center-four-fourth">
                                    <div style="padding:0 32px;">
                                        <div style="margin:40px 0px 14px;text-align: center;">
                                            <img src="../assets/scheme/four-fourth-four.png" alt="">
                                        </div>
                                        <div class="center-four-fourth-title"> 分析报告 </div>
                                        <p class="center-four-fourth-text">对普测进行全方位、多维度的数据分析，提供专业的分析报告，便于教育局和学校总结问题，进行针对性优化，建立测评优化长效机制。</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </template>
        </div>
    </div>
    <div class="center-five">
        <div class="center-five-content">
            <h1>心理危机筛查与干预长效机制</h1>
            <div style="width:1200px;margin:0 auto;" class="center-two-contentImg">
                <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/center-five.png" alt="">
            </div>
        </div>
    </div>

    <div class="center-six" style="display:none;">
        <div class="center-six-content">
            <h1>应用案例</h1>
            <div style="display:flex; justify-content: space-around; width:1200px; margin:0 auto;">
                <div class="center-six-content-left">
                    <div style="text-align: center; margin-top:32px;">
                        <img src="../assets/scheme/center-six-logo.png" alt="">
                    </div>
                    <h5 class="center-six-content-title">中阳县心理学会</h5>
                    <p  class="center-six-content-text">为了及时了解山西省中阳县基础教育学生的心理健康状况，提前识别心理危机事件，降低心理隐患。
                        心灵通与山西省中阳县心理学会个性化定制测评量表，完成近2000名学生心理测评工作，再由心理辅导教师对筛查结果进行访谈复核，并采取进一步的积极干预措施。
                        考虑到基础教育的基本情况，采取手机测评、机房统一测评和课堂纸质测评等多种方式，本次普查测评结果准确，为中阳县的基础教育心理危机筛查和干预提供了有力支撑。</p>
                </div>
                <div class="center-six-content-right">
                    <div class="block" arrow="never">
                        <el-carousel height= "500px" arrow="never" indicator-position="outside" >
                        <el-carousel-item v-for="item in imgs" style="height: 466px, width:282px" :key="item.url">
                            <img :src="item.url" alt="">
                        </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            activeName: 'first',
            imgs:[
                {url:require('../assets/scheme/lunboTwo-1.png')},
                {url:require('../assets/scheme/lunboTwo-2.png')}
            ]
        }
    },
    methods:{

    }
};
</script>


<style lang="less" scoped>
@import '../css/global.css';
.centerImg>img{
    width: 380px;
}
.topImg-title{
  line-height: 32px;
}
.topImg-headline{
  top: 70px;
}
.topImg-title,.topImg-text{
  font-weight: bold;
  font-size: 36px;
}
.center-one {
  width: 100%;
  background-color: #ffffff;
}
.center-one {
  width: 100%;
  background-color: #ffffff;
}
.center-content {
  width: 1200px;
  margin: 0 auto;
  height: 2000px;
  background: #ffffff;
}
.center-one{
    width: 100%;
    background-color: #ffffff;
}
.center-two-content ,.center-three-content,.center-four-content{
 margin: 0 auto;
 width: 1200px;
}
.center-fourImg>img{
    width: 282px;
}
.center-two-contentImg>img{
    width: 1200px;
}
.center-one-content{
  width: 1200px;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 74px;
}
h1{
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #3B3D41;
    letter-spacing: 6px;  
    text-align: center;
    padding-top: 60px;
    padding-bottom: 50px;
}
.center-one-content-title{
  text-align: center; 
  margin-top: 24px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3B3D41;
}
.center-two{
  width: 100%;
  background-color: #F8FAFF;
}
.center-two-content,.center-three-content,.center-five-content,.center-six-content{
    padding-bottom: 74px;
}
.alignItemsCenter{
    display: flex;
    align-items: center;
    margin-bottom: 60px;
}
.left-box{
    width: 280px;
}
.center-three{
  width: 100%;
  background: #ffffff   
}

.center-four{
    width: 100%;
    background: #F8FAFF;
}
.center-four-btn{
    width: 214px;
    height: 57px;
    line-height: 57px;
    text-align: center;
    background: linear-gradient(270deg, #4080FF 0%, #0156FF 100%);
    border-radius: 10px;
    border: none;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
}
.center-four-icon{
  width: 40px;
  height: 25px;
}
.center-four-first-title,.center-four-second-title{
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3B3D41;
    letter-spacing: 2px;
    margin-top: 50px;
    margin-bottom: 32px;
}
.center-four-img{
  margin-bottom: 74px;
  position: relative;
  z-index: 1;
}
.zhongdengBj{
  position: absolute;
  bottom: -4px;
  transform: translateX(-50%);
  left: 50%;
  z-index: 0;
  width: 1920px;
}
.zhongdengBj img{
  width: 100%;
}
.center-four-third-left{
    width: 752px;
    height: 448px;
    background: #FFFFFF;
    box-shadow: 6px 7px 13px 0px rgba(1, 86, 255, 0.09);
}
.center-four-third-left-title{
    margin-left: 16px;
    margin-right: 98px;
    white-space:nowrap;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #0156FF;
    letter-spacing: 3px;
}
ul>li{
    width: 360px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3B3D41;
    line-height: 32px;
    letter-spacing: 2px;
}
ul li::marker{
    color: #0156FF;
}
.center-four-third-right>img{
    width: 462px;
    height: 308px;
    background-color: plum;
}
.center-four-fourth{
    width: 282px;
    height: 303px;
    background: #FFFFFF;
    box-shadow: 0px 2px 9px 0px rgba(1, 86, 255, 0.1);
    border-radius: 10px;
}
.center-four-fourth-title{
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #0156FF;
    letter-spacing: 3px;
    margin-bottom: 24px;
    text-align: center;
}
.center-four-fourth-text{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3B3D41;
    letter-spacing: 2px;
}
.center-five{
    width: 100%;
    background: #FFFFFF;
}
.center-six{
    width: 100%;
    background: #F8FAFF;
}
.center-six-content-left{
    width: 542px;
    height: 513px;
    background: #FFFFFF;
    box-shadow: 0px 2px 11px 0px rgba(1, 86, 255, 0.1);
    border-radius: 10px;
}
.center-six-content-title{
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #3B3D41;
    letter-spacing: 3px;
    text-align: center;
    padding: 32px 40px;
}
.center-six-content-text{
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3B3D41;
    letter-spacing: 3px;
    padding: 0px 40px;
}
.center-six-content-right{
  width: 500px;
  height: 432px;
  justify-content: center;
}
.is-active{
    text-align: center;
}
.center-five-right .block{
  width: 282px;
}

/deep/.el-tabs__nav-scroll {
    overflow: hidden;
    display: flex;
    justify-content: space-around;
}
/deep/.el-tabs__item {
    padding: 0px 60px;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    font-weight: 400;
    letter-spacing: 3px;
    height: 50px;
}
/deep/.el-tabs__item:hover{
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 400;
    color: #3B3D41;
    letter-spacing: 3px;
}
// /deep/.is-active{
//     font-weight: 600 !important;
//     color: #3B3D41 !important;
// }
/deep/.el-tab-pane{
    width: 1200px;
    margin: 0 auto;
}
/deep/.el-tabs__header {
    margin: 0px;
}
/deep/.el-carousel__button{
  background: #888;
}
/deep/.el-tabs__active-bar{
  background-color: #0156FF;
}
/deep/.el-tabs__item.is-active {
 color: #0156FF;
}
/deep/.el-tabs__item:hover{
  color: #0156FF;
}
</style>