<template>
    <div class="box">
        <div class="topImg">
            <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/top-img.png" alt="" />
            <div class="topImg-headline">
                <div class="topImg-title">产品-硬件</div>
            </div>
        </div>
        <!-- 中心内容 -->
        <div class="content-center-box">
            <div class="content-center">
                <div class="content-centerImg">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/home/chuanpin-1.png" alt="">
                    <!-- 点击图片放大 -->
                    <!-- <img :src="scope.row.topicPicture" v-image-preview> -->
                </div>
                <div>
                    <div class="player-container">
                        <my-video :sources="video.sources" :options="video.options"></my-video>
                    </div>
                </div>
            </div>
        </div>

</div>
</template>
<script>
import myVideo from 'vue-video';

export default {
    data() {
        return {
            video: {
                sources: [{
                    src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/robot-video.mp4',
                    type: 'video/mp4',
                }],
                options: {
                    autoplay: false,
                    volume: 0.6,
                    poster: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/robot-video-cover.png',
                },
            },
        }
    },
    components: {
        myVideo
    },
    mounted() { },
    methods: {}
};
</script>
    
<style lang="less" scoped>
@import '../css/global.css';

.box {
    background: #EFF4FF;
}

// 中心内容
.topImg-title {
    line-height: 108px;
}

.topImg-headline {
    width: 1200px;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
}

.content-center-box {
    padding-bottom: 150px;
}

.content-center {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    background: #ffffff;
    margin-top: 60px;
    min-height: 600px;
    // height: 5344px;
}


.content-center>img {
    width: 1200px;
}

.content-centerImg {
    // height: 5018px;
    height: 5344px;
}

.content-centerImg>img {
    width: 1200px;
}

.player-container {
    position: absolute;
    right: 140px;
    bottom: 19.4%;
    width: 426px;
    height: 240px;
}

// 短视频
::v-deep .__cov-video-container {
    width: 426px;
    height: 240px;
}
</style>